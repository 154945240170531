document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.alert .btn-close').forEach(button => {
        button.addEventListener('click', () => {
            const alert = button.closest('.alert');
            alert.classList.remove('show');
            alert.classList.add('fade');
            setTimeout(() => alert.remove(), 150);
        });
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const generatePasswordButton = document.getElementById('generatePassword');
    const togglePasswordButton = document.getElementById('togglePassword');
    const passwordField = document.getElementById('password');

    if (generatePasswordButton && passwordField) {
        generatePasswordButton.addEventListener('click', () => {
            passwordField.value = generatePassword();
        });
    }

    if (togglePasswordButton && passwordField) {
        togglePasswordButton.addEventListener('click', () => {
            const passwordFieldType = passwordField.getAttribute('type');
            passwordField.setAttribute('type', passwordFieldType === 'password' ? 'text' : 'password');
            togglePasswordButton.innerHTML = passwordFieldType === 'password' ? '<i class="fas fa-eye-slash"></i>' : '<i class="fas fa-eye"></i>';
        });
    }

    function generatePassword() {
        const length = 12;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
        let password = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            password += charset.charAt(Math.floor(Math.random() * n));
        }
        return password;
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const addDepositPageJs = document.getElementById('add-deposit-page');
    if (!addDepositPageJs) {
        return;
    }

    const userSelect = document.getElementById('user_id');

    if (userSelect) {
        $(userSelect).select2({
            placeholder: 'Select User',
            allowClear: true,
            width: 'style' // Ensures Select2 width adapts to its container
        });
    }

    const dateInputs = document.querySelectorAll('input[type="date"]');
    dateInputs.forEach(input => {
        input.addEventListener('click', () => {
            input.showPicker(); // This will open the date picker directly
        });
    });
});

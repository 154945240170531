document.addEventListener('DOMContentLoaded', () => {
    const addInvestmentPageJs = document.getElementById('add-investment-page');
    if(!addInvestmentPageJs){
        return;
    }

    const attributesDiv = document.getElementById('attributes');
    const addAttributeButton = document.getElementById('addAttribute');
    const attributeTemplate = document.getElementById('attribute-template').content;
    const userSelect = document.getElementById('user_id');

    if (userSelect) {
        $(userSelect).select2({
            placeholder: 'Select User',
            allowClear: true,
            width: 'style' // Ensures Select2 width adapts to its container
        });
    }

    let attributes = JSON.parse(attributesDiv.dataset.attributes);
    let attributeIndex = attributes ? Object.keys(attributes).length : 0;

    const addAttribute = (key = '', value = '') => {
        const clone = document.importNode(attributeTemplate, true);
        const group = clone.querySelector('.attribute-group');
        const keyInput = group.querySelector('input[name="attributes[][key]"]');
        const valueInput = group.querySelector('input[name="attributes[][value]"]');

        keyInput.name = `attributes[${attributeIndex}][key]`;
        valueInput.name = `attributes[${attributeIndex}][value]`;
        keyInput.value = key;
        valueInput.value = value;

        group.querySelector('.remove-attribute').dataset.id = attributeIndex;
        group.classList.remove('d-none');
        attributesDiv.appendChild(clone);
        attributeIndex++;
    };

    addAttributeButton.addEventListener('click', () => {
        addAttribute();
    });

    attributesDiv.addEventListener('click', (e) => {
        if (e.target && e.target.classList.contains('remove-attribute')) {
            if (confirm('Are you sure you want to remove this attribute?')) {
                e.target.closest('.attribute-group').remove();
            }
        }
    });

    Object.entries(attributes).forEach(([key, value]) => {
        addAttribute(key, value);
    });

    const dateInputs = document.querySelectorAll('input[type="date"]');
    dateInputs.forEach(input => {
        input.addEventListener('click', () => {
            input.showPicker(); // This will open the date picker directly
        });
    });

    const clearButtons = document.querySelectorAll('.clear-date');
    clearButtons.forEach(button => {
        button.addEventListener('click', () => {
            const targetId = button.dataset.target;
            const targetInput = document.getElementById(targetId);

            if (targetInput) {
                targetInput.value = '';
            }
        });
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const withdrawForm = document.getElementById('withdraw-form');
    const withdrawButton = document.getElementById('withdrawButton');
    const withdrawAmountInput = document.getElementById('withdrawAmount');
    const errorMsg = document.getElementById('errorMsg');

    // Check if essential elements exist
    if (!withdrawForm || !withdrawButton || !withdrawAmountInput || !errorMsg) {
        console.warn("Warning: Some essential elements for the withdrawal form are missing.");
        return;
    }

    const requestWithdrawButton = withdrawForm.querySelector('.btn-warning');
    if (!requestWithdrawButton) {
        console.warn("Warning: Request withdrawal button is missing in the form.");
        return;
    }

    // Parse max amount and validate
    const maxAmount = parseFloat(withdrawAmountInput.dataset.maxAmount);
    if (isNaN(maxAmount) || maxAmount <= 0) {
        console.error("Error: Maximum allowable withdrawal amount is either missing or invalid.");
        return;
    }

    const toggleVisibility = (element, isVisible) => {
        if (element) {
            element.classList.toggle('d-none', !isVisible);
        }
    };

    const displayError = (message) => {
        if (errorMsg) {
            errorMsg.textContent = message;
        }
    };

    const validateAmount = (amount) => {
        if (isNaN(amount) || amount <= 0) {
            displayError("Prosím, zadajte platnú sumu.");
            return false;
        }
        if (amount > maxAmount) {
            displayError("Zadaná suma presahuje váš aktuálny zostatok.");
            return false;
        }
        displayError("");
        return true;
    };

    const confirmWithdrawal = (amount) => {
        return confirm(`Ste si istý, že chcete požiadať o výber vo výške ${amount.toFixed(2)} €?`);
    };

    withdrawButton.addEventListener('click', () => {
        toggleVisibility(withdrawButton, false);
        toggleVisibility(withdrawForm, true);
        withdrawForm.classList.add('d-flex');
    });

    requestWithdrawButton.addEventListener('click', (event) => {
        const amount = parseFloat(withdrawAmountInput.value);
        if (!validateAmount(amount) || !confirmWithdrawal(amount)) {
            event.preventDefault();
        } else {
            console.log("Odosielam formulár s výberom: suma", amount);
        }
    });
});
